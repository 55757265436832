import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/layout";
import Box from "components/box";
import Head from "components/head";
import { MDXRenderer } from "gatsby-plugin-mdx";

const About = ({ data }) => (
  <Layout>
    <Head pageTitle={data.aboutJson.title} />
    <Box>
      <MDXRenderer>{data.aboutJson.content.childMdx.body}</MDXRenderer>
    </Box>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired
};

export default About;

export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        absolutePath
        childMdx {
          body
        }
      }
    }
  }
`;
